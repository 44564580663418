import { FeatureCollection, Point } from 'geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useState } from 'react';
import { Layer, Map, Source } from 'react-map-gl';
import { clusterCountLayer, clusterLayer, unclusteredPointLayer, venueCirlceLayer, venueCountLayer } from './layers';
import { useEvents } from './hooks/useResults';



const EventMapClusterComponent: React.FC = () => {
  const { data, error, isLoading } = useEvents();

  // Always use venues from the first page
  const venues = data?.pages[0]?.venues;
  //const onLoaded = (map: mapboxgl.Map) => {
  //  map.resize();
  //}

  return (
    <>
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        initialViewState={{
          longitude: -122.441321,
          latitude: 37.768732,
          zoom: 12
        }}
        //style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
      >
        {venues && (
          <Source
            id="event_source"
            type="geojson"
            data={venues}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={50}
            clusterProperties={{ event_count_sum: ["+", ["get", "event_count"]]}}>
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...venueCirlceLayer} />
            <Layer {...venueCountLayer} />
            {/*<Layer {...unclusteredPointLayer} />*/}
          </Source>
        )}
        </Map>
      {/* Optionally, show a loading spinner or message while data is loading */}
      {isLoading && <div className="loading-spinner">Loading venues...</div>}
      
      {/* Optionally handle errors */}
      {error && <div className="error-message">Error loading venues: {error.message}</div>}
    </>
  );
}

export { EventMapClusterComponent as EventMapClusterComponent };
