// src/hooks/useEvents.ts
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { FeatureCollection, Point } from 'geojson';

interface EventVenue {
  id: number;
  name: string;
  location: {
    lat: number;
    lon: number;
  };
}

interface Event {
  id: number;
  name: string;
  location: {
    lat: number;
    lon: number;
  };
  start: string;
  ticket_url: string;
  image_url: string;
  venue: EventVenue;
}

interface EventsPage {
  events: Event[];
  event_count: number;
  page: number;
  page_size: number;
  num_pages: number;
}

interface EventsResponse {
  events_page: EventsPage;
  venues: FeatureCollection<Point>;
}

const fetchEvents = async ({
  pageParam = 1, // Default to the first page if no pageParam is provided
}: {
  pageParam?: number;
}): Promise<EventsResponse> => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const response = await fetch(
    `${apiUrl}/api/events/results?upcoming_within=P30D&page=${pageParam}&page_size=30`
  ); // Customize page_size as needed
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const useEvents = () => {
  return useInfiniteQuery<EventsResponse, Error>({
    queryKey: ['events'],
    queryFn: ({ pageParam }) =>
      fetchEvents({ pageParam: pageParam as number | undefined }), // Explicitly cast pageParam
    initialPageParam: 1, // Set the initial page parameter
    getNextPageParam: (lastPage) => {
      const { page, num_pages } = lastPage.events_page;
      return page < num_pages ? page + 1 : undefined; // Return the next page or undefined if it's the last page
    },
  });
};
