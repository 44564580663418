import './App.css';
import { EventMapClusterComponent } from './EventMapClusterComponent';
import FilterBar from './FilterBar';
import ResultsTiles from './ResultsTiles';


function App() {
  return (    
    <div className="App">
      <header className="App-header">
        <div className="App-header-left">
          <img src="/fanflame_small.png" className="App-logo" alt="logo" />
          <span className="App-title">FanFlame</span>
        </div>
      </header>
      <div className="App-body">
        <div className="results-tiles-container">
          <ResultsTiles />
        </div>
        <div className="map-container">
          <EventMapClusterComponent />
        </div>
      </div>
    </div>
  );
}

export default App;