import React, { useEffect, useRef, useState } from 'react';
import './ResultsTiles.css';
import { useEvents } from './hooks/useResults';


const ResultsTiles: React.FC = () => {
  //  const { data, error, isLoading } = useEvents();
  const { data, error, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useEvents();
  const observerRef = useRef<HTMLDivElement | null>(null);


  //const events_page = data?.events_page;
  const allEvents = data?.pages.flatMap((page) => page.events_page.events) || [];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null, // Use the viewport
        rootMargin: '200px', // Trigger slightly before reaching the bottom
        threshold: 0.1, // Trigger when 10% of the sentinel is visible
      }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [fetchNextPage, hasNextPage]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const optionsDate: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      month: 'short',
      day: '2-digit'
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };
    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
    return `${formattedDate} • ${formattedTime}`;
  };

  if (isLoading) return <div className="results-tiles">Loading...</div>;
  if (error) return <div className="results-tiles">Error: {error.message}</div>;

  return (
    <div className="results-tiles">
      {allEvents.map((event) => (
        <div key={event.id} className="event-tile">
          {event.image_url && (
            <img src={event.image_url} alt={event.name} className="event-image" />
          )}
          <h2>{event.name}</h2>
          <h3>{event.venue.name}</h3>
          <p>{formatDate(new Date(event.start).toLocaleString())}</p>
          <a href={event.ticket_url} target="_blank" rel="noopener noreferrer">
            Get Tickets
          </a>
        </div>
      ))}
      {/* Sentinel for triggering the next page */}
      <div ref={observerRef} className="loading-sentinel" />
      {/* Small loading widget */}
      {isFetchingNextPage && <div className="loading-spinner">Loading more...</div>}
    </div>
  );
};

export default ResultsTiles;
